import { fetchWrapper } from '@/helpers';

const ruta = process.env.VUE_APP_ROOT_API;

export default class UnidadmedidaService {
  async getUnidadmedidasAll() {
    const unidadmedidas = await fetchWrapper.get(`${ruta}/unidadmedida`);
    return unidadmedidas;
  }
  async sendUnidadmedidaNueva(unidadmedida) {
    const nuevaUnidadmedida = await fetchWrapper.post(
      `${ruta}/unidadmedida`,
      unidadmedida
    );
    return nuevaUnidadmedida;
  }
  async updatedUnidadmedida(unidadmedida) {
    const unidadmedidaActualizada = await fetchWrapper.put(
      `${ruta}/unidadmedida/` + unidadmedida.id,
      unidadmedida
    );
    return unidadmedidaActualizada;
  }
  async deleteUnidadmedida(unidadmedida) {
    const unidadmedidaEliminada = await fetchWrapper.delete(
      `${ruta}/unidadmedida/` + unidadmedida.id
    );
    return unidadmedidaEliminada;
  }
  async importUnidadmedidaNueva(unidadmedida) {
    const nuevaUnidadmedida = await fetchWrapper.postFiles(
      `${ruta}/unidadmedidas-import`,
      unidadmedida
    );
    return nuevaUnidadmedida;
  }
  async exportUnidadmedidasAll() {
    const unidadmedidas = await fetchWrapper.getWithBlob(`${ruta}/unidadmedidas-export`);
    return unidadmedidas;
  }
  /*  async showUnidadmedida(unidadmedida) {
    const unidadmedidaShow = await fetchWrapper.get(
      `${ruta}/unidadmedida/` + unidadmedida
    );
    return unidadmedidaShow;
  } */
}
