<template>
	<Dialog
		v-model:visible="mostrarModal"
		:style="{ width: '450px' }"
		header="Unidad de Medida Detalle"
		:modal="true"
		@hide="ocultarDialog"
		class="p-fluid"
	>
		<div class="field">
			<label for="nombre"><strong>Nombre: </strong> <span class="p-invalid">*</span></label>
			<InputText
				id="nombre"
				v-model.trim="data_unidad_medida.nombre"
				required="true"
				autofocus
			/>
			<small class="p-invalid" v-if="errors.nombre">{{
				errors.nombre[0]
			}}</small>
		</div>
		<div class="field">
			<label for="estado"><strong>Estado </strong></label>
			<Dropdown
				id="estado"
				v-model="data_unidad_medida.estado"
				:disabled="!data_unidad_medida.id"
				:options="estados"
				optionLabel="label"
				placeholder="Seleccione un estado"
			>
				<template #value="slotProps">
					<span :class="'product-badge ' + claseEstado[slotProps.value.value]">
						{{ slotProps.value.label }}
					</span>
				</template>
			</Dropdown>
		</div>
		<template #footer>
			<div class="field">
				<label class="flex md:justify-content-start"
					><strong>Campos Requeridos: </strong> <span class="p-invalid">*</span></label
				>
			</div>
			<Button
				label="Cancelar"
				icon="pi pi-times"
				class="p-button-secondary p-button-lg"
				@click="ocultarDialog"
				:disabled="!enviado ? false : true"
			/>
			<Button
				label="Guardar"
				icon="pi pi-check"
				class="p-button-primary p-button-lg"
				@click="guardarUnidadmedida"
				:disabled="!enviado ? false : true"
				:loading="enviado"
			/>
		</template>
	</Dialog>
</template>

<script>
import UnidadmedidaService from "@/service/UnidadmedidaService";
export default {
	emits: ["closeModal", "actualizarListado"],
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		unidadmedida: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	unidadmedidaService: null,
	data() {
		return {
			mostrarModal: this.show,
			data_unidad_medida: this.unidadmedida,
			enviado: false,
			estados: [
				{ label: "Inactivo", value: 0 },
				{ label: "Activo", value: 1 },
			],
			errors: {},
			claseEstado: ["status-outofstock", "status-instock"],
		};
	},
	created() {
		this.unidadmedidaService = new UnidadmedidaService();
	},
	watch: {
		show(val) {
			this.mostrarModal = val;
		},
		unidadmedida(val) {
			this.data_unidad_medida = val;
		},
	},
	methods: {
		ocultarDialog() {
			/*     if(this.enviado ){
        return false;
      } */
			this.errors = {};
			this.mostrarModal = false;
			this.$emit("closeModal");
		},
		guardarUnidadmedida() {
			this.errors = {};
			this.enviado = true;

			//editar Unidad de Medida
			if (this.unidadmedida.id) {
				let unidadmedida_enviar = {
					...this.data_unidad_medida,
					estado: this.data_unidad_medida.estado.value,
				};
				this.unidadmedidaService
					.updatedUnidadmedida(unidadmedida_enviar)
					.then((data) => {
						if (Object.prototype.hasOwnProperty.call(data, "errors")) {
							this.errors = data.errors;
						} else {
							this.errors = {};
							this.$toast.add({
								severity: "success",
								summary: "Exito!",
								detail: data.mensaje,
								life: 3000,
							});
							this.$emit("actualizarListado");
							this.ocultarDialog();
						}
						this.enviado = false;
					});
			} else {
				//CREANDO UN NUEVO PROVEEDOR
				let tmp = this.data_unidad_medida;
				this.data_unidad_medida = {
					...this.data_unidad_medida,
					estado: this.data_unidad_medida.estado.value,
				};
				this.unidadmedidaService
					.sendUnidadmedidaNueva(this.data_unidad_medida)
					.then((data) => {
						if (Object.prototype.hasOwnProperty.call(data, "errors")) {
							this.errors = data.errors;
							this.data_unidad_medida = tmp;
						} else {
							this.errors = {};

							this.$toast.add({
								severity: "success",
								summary: "Exito!",
								detail: "Unidad de Medida Creada",
								life: 10000,
							});
							this.$emit("actualizarListado");
							this.ocultarDialog();
						}
						this.enviado = false;
					});
			}
		},
	},
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
	color: red;
}
</style>
